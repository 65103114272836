export const validateCredentials = async (username: string, password: string): Promise<boolean> => {
    // For local testing only
    if (process.env.NODE_ENV === 'development' && username === 'admin' && password === 'admin') {
        // Set auth token when credentials are valid
        localStorage.setItem('auth_token', 'admin-token');
        return true;
    }
    return false;
};

export interface User {
    id: string;
    username: string;
    isAdmin: boolean;
}

export const getDefaultAdminUser = (): User => ({
    id: 'admin',
    username: 'admin@admin.com',
    isAdmin: true
});

// Add these new functions
export const isAuthenticated = (): boolean => {
    return !!localStorage.getItem('auth_token');
};

export const signOut = () => {
    // Clear all authentication-related storage
    localStorage.clear();
    sessionStorage.clear();

    // If you're using any auth tokens or specific items, clear them explicitly
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // If you're using any auth state management (like Redux or Context), reset it here
    // For example: dispatch(resetAuthState());
};

export const checkAuth = async (): Promise<boolean> => {
    if (process.env.NODE_ENV === 'development') {
        return true;
    }
    // Your existing auth check logic
    const token = localStorage.getItem('token');
    return !!token;
};