import { create } from 'zustand';
import { Complaint } from '../models/complaint';

interface ComplaintState {
  complaint: Partial<Complaint>;
  updateField: (field: keyof Complaint, value: any) => void;
  resetComplaint: () => void;
}

export const useComplaintStore = create<ComplaintState>((set) => ({
  complaint: {},
  updateField: (field, value) =>
    set((state) => ({
      complaint: { ...state.complaint, [field]: value },
    })),
  resetComplaint: () =>
    set(() => ({
      complaint: {},
    })),
}));
