import React from 'react';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';

interface MapProps extends google.maps.MapOptions {
  center: google.maps.LatLngLiteral;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onLoad?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const mapContainerStyle = {
  width: '100%',
  height: '300px',
  marginTop: '1rem',
  borderRadius: '0.5rem'
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ color: "#242f3e" }]
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }]
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [{ color: "#746855" }]
    }
  ]
};

export function Map({ center, onClick, onLoad, children }: MapProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={15}
      onClick={onClick}
      onLoad={onLoad}
      options={options}
      mapContainerClassName="map-container"
    >
      <Marker
        position={center}
      />
      {children}
    </GoogleMap>
  );
}