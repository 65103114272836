export interface SubcategoryInfo {
  id: string;
  name: string;
  description?: string;
}

export interface CategoryInfo {
  id: string;
  name: string;
  description: string;
  icon: string;
  priority: number;
  subcategories?: SubcategoryInfo[];
}

export interface Complaint {
  category: string;
  description: string;
  location?: string;
  date: Date;
  contactName: string;
  email: string;
  phone: string;
  photoEvidence?: string;
  evidenceDescription?: string;
  knowsWastedAmount?: boolean;
  wastedAmount?: string;
  type: string;
  subcategory?: any;
  xLinks: string[];
}

export interface ComplaintType {
  name: string;
}

export const categories: CategoryInfo[] = [
  {
    id: 'local-crime',
    name: 'Criminal Activity',
    priority: 1,
    description: 'Issues related to drug & human trafficking, violent & property crime, or prosecutorial misconduct.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    `,
    subcategories: [
      { id: 'drug-trafficking', name: 'Drug Trafficking', description: 'Illegal drug activities.' },
      { id: 'human-trafficking', name: 'Human Trafficking', description: 'Exploitation of people for profit.' },
      { id: 'violent-crime', name: 'Violent Crime', description: 'Crimes involving violence.' },
      { id: 'property-crime', name: 'Property Crime', description: 'Crimes involving theft or property damage.' },
      { id: 'retail-crime', name: 'Retail Crime', description: 'Crimes targeting retail businesses.' },
      { id: 'lack-of-prosecution', name: 'Lack of Prosecution or Political Prosecution', description: 'Issues with prosecutorial actions or inactions.' },
      { id: 'other-local-crime', name: 'Other', description: 'Other types of local crimes.' }
    ]
  },
  {
    id: 'homelessness-housing-sanitation',
    name: 'Homelessness, Housing, Sanitation',
    priority: 2,
    description: 'Issues related to encampments, open-air drug use, or human and animal waste.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M3 21h18M3 10h18v11H3V10zM3 7l9-4 9 4M4 10h16v11H4V10z" />
      </svg>
    `,
    subcategories: [
      { id: 'affordable-housing-needs', name: 'Affordable Housing Needs', description: 'Demand for affordable housing solutions.' },
      { id: 'homelessness-enforcement', name: 'Homelessness Enforcement', description: 'Policies and actions related to enforcing homelessness laws.' },
      { id: 'sober-living-homes', name: 'Sober Living Homes', description: 'Issues concerning sober living facilities for the homeless.' }
    ]
  },
  {
    id: 'immigration',
    name: 'Immigration and Border Security',
    priority: 3,
    description: 'Issues where resources are being diverted to support breaking U.S. immigration laws.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
      </svg>
    `,
    subcategories: [
      { id: 'sanctuary-cities', name: 'Sanctuary Cities', description: 'Policies of sanctuary cities affecting immigration enforcement.' },
      { id: 'human-sex-trafficking', name: 'Human and Sex Trafficking', description: 'Trafficking issues related to immigration.' },
      { id: 'illegal-crossing-locations', name: 'Illegal Crossing Locations', description: 'Locations where illegal border crossings occur.' },
      { id: 'human-caravans', name: 'Human Caravans Located', description: 'Presence of large groups crossing borders.' },
      { id: 'cartel-activity', name: 'Cartel Activity', description: 'Illegal activities by cartels affecting immigration.' },
      { id: 'safe-house-business-locations', name: 'Safe House or Business Locations', description: 'Businesses or houses used to support illegal immigration.' },
      { id: 'gov-funding-social-costs', name: 'Government Funding for Social Costs', description: 'Funding diverted to support social services for illegal immigrants.' }
    ]
  },
  {
    id: 'voter-rights',
    name: 'Voter Rights',
    priority: 4,
    description: 'Election integrity issues including voter registration, ballot counting, civil unrest, and protests.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
      </svg>
    `,
    subcategories: [
      { id: 'ballot-counts', name: 'Ballot Counts', description: 'Issues with counting of ballots.' },
      { id: 'early-voting', name: 'Early Voting', description: 'Problems related to early voting processes.' },
      { id: 'ballot-harvesting', name: 'Ballot Harvesting', description: 'Concerns over ballot collection practices.' },
      { id: 'petition-signatures', name: 'Petition Signatures', description: 'Issues with petition signature verification.' },
      { id: 'civil-unrest-protest', name: 'Civil Unrest Protest', description: 'Protests affecting election processes.' },
      { id: 'public-official-misconduct', name: 'Public Official Misconduct', description: 'Misconduct by public officials.' },
      { id: 'general-voter-issues', name: 'General Issues', description: 'Other voter-related concerns.' }
    ]
  },
  {
    id: 'local-education',
    name: 'Local Education',
    priority: 5,
    description: 'Issues related to gender & race misinformation, misuse of K-12 & higher education funds, or teachers\' union overreach.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M12 14l9-5-9-5-9 5 9 5z" />
        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
      </svg>
    `,
    subcategories: [
      { id: 'education', name: 'Education', description: 'General education issues.' },
      { id: 'misuse-k12-funds', name: 'Misuse of K-12 Funds', description: 'Improper allocation of K-12 education funds.' },
      { id: 'political-advocacy', name: 'Political Advocacy by School District or Official', description: 'Political actions by educational officials.' },
      { id: 'misuse-higher-edu-funds', name: 'Misuse of Higher Education Funds', description: 'Improper allocation of higher education funds.' },
      { id: 'gender-misinformation', name: 'Gender Misinformation Violations', description: 'Spread of incorrect gender-related information.' },
      { id: 'union-overreach', name: 'Union Overreach', description: 'Excessive actions by teachers\' unions.' }
    ]
  },
  {
    id: 'infrastructure',
    name: 'Infrastructure',
    priority: 6,
    description: 'Issues related to federal, state, or local transportation, bridges, buildings, parks, or utilities.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
      </svg>
    `,
    subcategories: [
      { id: 'construction-waste', name: 'Government Construction and Building Waste', description: 'Waste generated from government construction projects.' },
      { id: 'transportation', name: 'Transportation', description: 'Issues related to transportation infrastructure.' },
      { id: 'airports', name: 'Airports', description: 'Problems concerning airport facilities and operations.' },
      { id: 'bridges', name: 'Bridges', description: 'Issues with bridge maintenance and safety.' },
      { id: 'parks', name: 'Parks', description: 'Issues related to public parks and recreational areas.' },
      { id: 'railroads-subways', name: 'Railroads and Metro Subways', description: 'Problems with rail and subway systems.' },
      { id: 'roadways', name: 'Roadways', description: 'Issues concerning roads and highways.' },
      { id: 'telecom-wireless', name: 'Telecom/Wireless Needs', description: 'Infrastructure needs for telecommunications and wireless services.' }
    ]
  },
  {
    id: 'public-censorship',
    name: 'Public Censorship',
    priority: 7,
    description: 'Issues related to First Amendment rights, FCC violations, or misuse of public broadcast rights.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
      </svg>
    `,
    subcategories: [
      { id: 'censorship', name: 'Censorship', description: 'Suppression of speech or information.' },
      { id: 'free-speech-violation', name: 'Free Speech Violation', description: 'Violations by government entities.' },
      { id: 'media-watch', name: 'Media Watch', description: 'Monitoring media for biases or misinformation.' },
      { id: 'parental-guidelines-violation', name: 'Parental Guidelines Violation', description: 'Issues with adherence to parental guidelines.' },
      { id: 'broadcast-spectrum-misuse', name: 'Broadcast Spectrum Misuse', description: 'Improper use of broadcast frequencies.' },
      { id: 'public-records-violation', name: 'FOIA or Public Records Violation', description: 'Violations of Freedom of Information Act.' },
      { id: 'gov-tech-cooperation', name: 'Government Working with Technology Company', description: 'Concerns over government collaboration with tech firms.' }
    ]
  },
  {
    id: 'lawfare',
    name: 'Lawfare',
    priority: 8,
    description: 'Punitive litigation brought by a federal, state, or local government or agency against an individual or company.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
      </svg>
    `,
    subcategories: [
      { id: 'federal-lawfare', name: 'Federal Lawfare', description: 'Litigation or legal resource misuse by a federal agency or department' },
      { id: 'local-lawfare', name: 'Local Lawfare', description: 'Litigation or legal resource misuse by a county, city, or town' },
      { id: 'state-lawfare', name: 'State Lawfare', description: 'Litigation or legal resource misuse by a state agency or department' }
    ]
  },
  {
    id: 'federal-agency-waste',
    name: 'Agency Waste',
    priority: 9,
    description: 'Spending mismanagement related to all agencies within government',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
      </svg>
    `,
    subcategories: [
      { id: 'federal-agency', name: 'Federal Agency', description: 'Issues with federal agency spending and management.' },
      { id: 'state-agency', name: 'State Agency', description: 'Issues with state agency spending and management.' },
      { id: 'local-jurisdiction-agency', name: 'Local Jurisdiction/Agency', description: 'Issues with local agency spending and management.' },
      { id: 'procurement-violations', name: 'Procurement Violations', description: 'Violations in government procurement processes.' }
    ]
  },
  {
    id: 'public-lands-environmental',
    name: 'Public Lands and Environmental',
    priority: 10,
    description: 'Issues related to bureaucracy, pollution, waste management, or environmental damage.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    `,
    subcategories: [
      { id: 'regulatory-overreach', name: 'Government Regulatory Overreach', description: 'Excessive government regulations.' },
      { id: 'fema-response', name: 'FEMA Response', description: 'Issues with FEMA\'s disaster response.' },
      { id: 'clean-air-water-concerns', name: 'Clean Air or Water Concerns', description: 'Issues related to air and water pollution.' },
      { id: 'local-zoning', name: 'Local Zoning', description: 'Problems with local zoning laws and regulations.' }
    ]
  },
  {
    id: 'other',
    name: 'Other',
    priority: 11,
    description: 'Any other federal issues not covered by the categories above.',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
          d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
      </svg>
    `,
    subcategories: [
     
      { id: 'misc-federal-concerns', name: 'Miscellaneous Federal Concerns', description: 'Miscellaneous concerns at the federal level.' },
      { id: 'misc-state-concerns', name: 'Miscellaneous State Concerns', description: 'Miscellaneous concerns at the state level.' },
      { id: 'misc-local-concerns', name: 'Miscellaneous Local Concerns', description: 'Miscellaneous concerns at the local level.' }
    ]
  }
];

// Add a sort function to ensure categories are always in priority order
export const getSortedCategories = () => {
  return [...categories].sort((a, b) => a.priority - b.priority);
};

export const complaintTypes: ComplaintType[] = [
  { name: 'Waste' },
  { name: 'Fraud' },
  { name: 'Corruption' },
  { name: 'Unauthorized Rulemaking' },
  { name: 'Other Misuse' }
];