import OAuth from 'oauth-1.0a';

interface TwitterTokens {
  oauth_token: string;
  oauth_token_secret: string;
}

interface TwitterUser {
  id: string;
  username: string;
  accessToken: string;
  accessSecret: string;
}

interface XAuthResponse {
  email: string;
  id: string;
  username: string;
  // Add other user fields you need
}

const TWITTER_API_KEY = import.meta.env.VITE_TWITTER_API_KEY;
const TWITTER_API_SECRET = import.meta.env.VITE_TWITTER_API_SECRET;
const TWITTER_ACCESS_TOKEN = import.meta.env.VITE_TWITTER_ACCESS_TOKEN;

const CALLBACK_URL = typeof window !== 'undefined'
  ? `${window.location.origin}/auth/callback`
  : 'http://localhost:5173/auth/callback';

const generateRandomBytes = (size: number): string => {
  const array = new Uint8Array(size);
  window.crypto.getRandomValues(array);
  return Array.from(array)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
};

const createHmacSignature = async (key: string, message: string): Promise<string> => {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  // Import the key
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );

  // Create the signature
  const signature = await window.crypto.subtle.sign(
    'HMAC',
    cryptoKey,
    messageData
  );

  // Convert to base64
  return btoa(String.fromCharCode(...new Uint8Array(signature)));
};

const oauth = new OAuth({
  consumer: {
    key: TWITTER_API_KEY,
    secret: TWITTER_API_SECRET
  },
  signature_method: 'HMAC-SHA1',
  hash_function: (base_string: string, key: string) => {
    // Force synchronous execution of async function
    let signature = '';
    createHmacSignature(key, base_string)
      .then(sig => { signature = sig; });
    while (!signature) {
      // Wait for signature
    }
    return signature;
  }
});

export const loginWithX = async (): Promise<void> => {
  try {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Store code verifier
    sessionStorage.setItem('twitter_oauth_code_verifier', codeVerifier);

    // Construct auth URL
    const authUrl = new URL('https://twitter.com/i/oauth2/authorize');
    authUrl.searchParams.append('response_type', 'code');
    authUrl.searchParams.append('client_id', import.meta.env.VITE_TWITTER_CLIENT_ID);
    authUrl.searchParams.append('redirect_uri', import.meta.env.VITE_TWITTER_CALLBACK_URL);
    authUrl.searchParams.append('scope', 'tweet.read users.read offline.access');
    authUrl.searchParams.append('state', 'state');
    authUrl.searchParams.append('code_challenge', codeChallenge);
    authUrl.searchParams.append('code_challenge_method', 'S256');

    // Redirect to Twitter
    window.location.href = authUrl.toString();
  } catch (error) {
    console.error('X login error:', error);
    throw error;
  }
};

export const handleXCallback = async (code: string): Promise<any> => {
  try {
    const codeVerifier = sessionStorage.getItem('twitter_oauth_code_verifier');
    if (!codeVerifier) {
      throw new Error('No code verifier found');
    }

    console.log('Making API request with:', { code, code_verifier: codeVerifier }); // Debug log

    const response = await fetch(import.meta.env.VITE_LAMBDA_TWITTER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        code_verifier: codeVerifier
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('API error:', errorData); // Debug log
      throw new Error(errorData.error || 'Failed to authenticate');
    }

    const data :any = await response.json();
    console.log('API response:', data); // Debug log

    // Clean up
    sessionStorage.removeItem('twitter_oauth_code_verifier');

    return {
      user: {
        id: data.user.data.id,
        name: data.user.data.name,
        username: data.user.data.username,
        profile_image_url: data.user.data.profile_image_url
      },
      token: {
        access_token: data.token.access_token,
        refresh_token: data.token.refresh_token
      }
    };
  } catch (error) {
    console.error('X callback error:', error);
    throw error;
  }
};

// Helper functions for PKCE
function generateCodeVerifier(): string {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
}

async function generateCodeChallenge(verifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode(...new Uint8Array(hash)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}