import React, { useState } from "react";

const useFileHandler = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

  const handleUpload = async (file: any) => {
    if (!file) {
      alert("Please select a file");
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Step 1: Get pre-signed URL from Lambda
      const response = await fetch(
        "https://he9nwo17ck.execute-api.us-east-1.amazonaws.com/",
        {
          method: "POST",
          body: JSON.stringify({
            fileName: file.name,
            fileType: file.type,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get pre-signed URL");
      }

      const { uploadURL, key } = await response.json();

      // Step 2: Upload file to S3
      const uploadResponse = await fetch(uploadURL, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file to S3");
      }

      // Step 3: Display uploaded file URL
      const fileUrl = `https://civicsonetest.s3.us-east-1.amazonaws.com/${key}`;
      setUploadedUrl(fileUrl);
      return fileUrl;
    } catch (err: any) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  const handleFileDelete = async (s3Url: string) => {
    try {
      setUploading(true);
      const response = await fetch(
        "https://ccirg0z11b.execute-api.us-east-1.amazonaws.com/",
        {
          method: "POST",
          body: JSON.stringify({
            s3Url,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (!response.ok) {
          throw new Error("Failed to delete file from S3");
        }
    
        setUploadedUrl(null);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  }

    return { uploading, error, uploadedUrl, handleUpload, handleFileDelete};
};

export default useFileHandler;
