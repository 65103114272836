import React from 'react';

interface NavigationButtonsProps {
  currentStep: number;
  canProceed: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

export function NavigationButtons({ 
  currentStep, 
  canProceed, 
  onPrevious, 
  onNext 
}: NavigationButtonsProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black border-t border-gray-800 p-4">
      <div className="flex gap-4">
        {currentStep > 1 && (
          <button
            onClick={onPrevious}
            className="flex-1 bg-gray-900 hover:bg-gray-800 text-white p-3 rounded-lg transition-colors"
          >
            Previous
          </button>
        )}
        <button
          onClick={onNext}
          disabled={!canProceed}
          className={`flex-1 ${
            canProceed
              ? 'bg-[#1D9BF0] hover:bg-[#1a8cd8] text-white'
              : 'bg-gray-800 text-gray-500 cursor-not-allowed'
          } p-3 rounded-lg transition-colors`}
        >
          {currentStep < 3 ? 'Next' : 'Submit'}
        </button>
      </div>
    </div>
  );
}