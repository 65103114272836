import { Complaint, categories } from '../../models/complaint';
import { CategorySelector } from '../CategorySelector';
import { useComplaintStore } from '../../store/complaintStore';


export function Step1() {
  const { complaint, updateField } = useComplaintStore();

  return (
    <div className="space-y-6 pt-6">
      <h2 className="text-2xl font-bold text-white text-center mt-2">
        You are the eyes and ears of our nation.
      </h2>
      <div className="space-y-6 pt-2">
        <CategorySelector
          question="What type of local, state, or national issue are you exposing?"
          categories={categories}
          selectedCategoryId={complaint.category || ''}
          onCategorySelect={(categoryId) => updateField('category', categoryId)}

        />
      </div>

      <div className="space-y-4 border-t border-gray-800 pt-6">
        <label className="text-lg font-semibold text-gray-200" htmlFor="knowsWastedAmount">Do you know how much money is wasted?</label>
        <div className="flex gap-4">
          <button
            onClick={() => updateField('knowsWastedAmount', true)}
            className={`flex-1 p-3 rounded-lg transition-all duration-300
              ${complaint.knowsWastedAmount === true
                ? 'bg-[#1D9BF0] text-white'
                : 'bg-gray-900 text-gray-300 hover:bg-gray-800'}`}
          >
            Yes
          </button>
          <button
            onClick={() => {
              updateField('knowsWastedAmount', false);
              updateField('wastedAmount', '');
            }}
            className={`flex-1 p-3 rounded-lg transition-all duration-300
              ${complaint.knowsWastedAmount === false
                ? 'bg-[#1D9BF0] text-white'
                : 'bg-gray-900 text-gray-300 hover:bg-gray-800'}`}
          >
            No
          </button>
        </div>
      </div>

      {complaint.knowsWastedAmount && (
        <div className="space-y-2">
          <label className="text-lg font-semibold text-gray-200" htmlFor="wastedAmount">
            Please enter the amount that you believe is wasted annually due to this issue
          </label>
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">$</span>
            <input
              id="wastedAmount"
              type="number"
              min="0"
              step="1"
              value={complaint.wastedAmount || ''}
              onChange={(e) => updateField('wastedAmount', e.target.value)}
              className="w-full p-3 pl-8 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                       placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent"
              placeholder="Enter amount"
            />
          </div>
        </div>
      )}
    </div>
  );
}