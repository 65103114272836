interface CategorySelectorProps {
  question: string;
  categories: { id: string; name: string; description: string; icon: string }[];
  selectedCategoryId: string;
  onCategorySelect: (categoryId: string) => void;
}

export function CategorySelector({
  question,
  categories,
  selectedCategoryId,
  onCategorySelect
}: CategorySelectorProps) {
  return (
    <div className="space-y-6">
      <label className="text-lg font-semibold text-gray-200">{question}</label>
      <div className="space-y-3">
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() => onCategorySelect(category.id)}
            className={`w-full p-4 rounded-lg flex items-center space-x-4 transition-all duration-300
              ${selectedCategoryId === category.id
                ? 'bg-[#1D9BF0] text-white scale-[1.02] shadow-lg'
                : 'bg-gray-900 text-gray-300 hover:bg-gray-800 hover:scale-[1.01]'}`}
          >
            <div
              className={`w-12 h-12 flex-shrink-0 rounded-xl flex items-center justify-center
                ${selectedCategoryId === category.id ? 'bg-[#1a8cd8]' : 'bg-gray-800'}`}
              dangerouslySetInnerHTML={{ __html: category.icon }}
            />
            <div className="flex-1 text-left">
              <h3 className="font-medium">{category.name}</h3>
              <p className={`text-sm ${selectedCategoryId === category.id ? 'text-blue-100' : 'text-gray-400'}`}>
                {category.description}
              </p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
