import React, { useState, useEffect } from 'react';
import { Complaint } from '../models/complaint';
import { Header } from './Header';
import { ProgressBar } from './ProgressBar';
import { NavigationButtons } from './NavigationButtons';
import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step3 } from './steps/Step3';
import { TweetDialog } from './TweetDialog';
import { useNavigate } from 'react-router-dom';
import { signOut, checkAuth } from '../utils/auth';
import { useComplaintStore } from '../store/complaintStore';
import NavBar from './NavBar';

const departmentHandles = {
  'local-crime': '@TheJusticeDept',
  'public-censorship': '@FCC',
  'local-education': '@usedgov',
  'public-lands-environmental': '@EPA',
  'immigration': '@DHSgov',
  'infrastructure': '@USDOT',
  'homelessness-housing-sanitation': '@HUDgov',
  'voter-rights': '@FEC',
  'other': '@WhiteHouse'
};

export default function ComplaintForm() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const { complaint, resetComplaint } = useComplaintStore();
  const [showTweetDialog, setShowTweetDialog] = useState(false);
  const [generatedTweet, setGeneratedTweet] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const validateAuth = async () => {
      try {
        const isAuthenticated = await checkAuth();
        if (!isAuthenticated) {
          navigate('/login', { replace: true });
          return;
        }
      } catch (error) {
        console.error('Auth validation error:', error);
        navigate('/login', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    validateAuth();
  }, [navigate]);


  const canProceed = () => {
    switch (currentStep) {
      case 1:
        return !!complaint.category &&
          typeof complaint.knowsWastedAmount === 'boolean' &&
          (!complaint.knowsWastedAmount || !!complaint.wastedAmount);
      case 2:
        return !!complaint.description?.trim() &&
          !!complaint.location?.trim() &&
          !!complaint.date;
      case 3:
        return !!complaint.contactName?.trim() &&
          !!complaint.email?.trim() &&
          !!complaint.phone?.trim();
      default:
        return false;
    }
  };

  const generateTweet = () => {
    const handle = departmentHandles[complaint.category as keyof typeof departmentHandles] || '@WhiteHouse';
    let tweet = `I just filed a complaint about ${complaint.category} issues in ${complaint.location}. ${handle} please look into this matter.`;

    if (complaint.knowsWastedAmount && complaint.wastedAmount) {
      tweet += ` Estimated annual waste: $${complaint.wastedAmount}`;
    }

    tweet += ' #CivicEngagement #CommunityAction';
    return tweet;
  };

  const submitComplaint = async () => {
    const tweet = generateTweet();
    setGeneratedTweet(tweet);
    setShowTweetDialog(true);
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(prev => prev + 1);
    } else if (canProceed()) {
      submitComplaint();
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleSignOut = () => {
    try {
      signOut();
      // Force clear any remaining auth state
      localStorage.clear();
      sessionStorage.clear();

      // Use replace instead of href to prevent back navigation
      window.location.replace('https://civics-one.vercel.app/login');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black text-gray-100 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#1D9BF0]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-gray-100">

      <NavBar />

      <Header />
      <ProgressBar currentStep={currentStep} />

      <main className="pt-36 pb-24 px-4">
        {currentStep === 1 && (
          <Step1 />
        )}
        {currentStep === 2 && (
          <Step2 />
        )}
        {currentStep === 3 && (
          <Step3 />
        )}
      </main>

      <NavigationButtons
        currentStep={currentStep}
        canProceed={canProceed()}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />

      <TweetDialog
        isOpen={showTweetDialog}
        tweet={generatedTweet}
        onClose={() => {
          setShowTweetDialog(false);
          setCurrentStep(1);
        }}
      />
    </div>
  );
}