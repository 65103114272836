import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ComplaintForm from './components/ComplaintForm';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { XCallback } from './pages/XCallback';
import { FileUploadTest } from './pages/FileUploadTest';
import { useUserStore } from './store/userStore';

function App() {
  const { setUser, isAuthenticated, setIsAuthenticated } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const mockUser = {
        id: 'dev-user',
        name: 'Development User',
        username: 'devuser',
        profile_image_url: 'https://via.placeholder.com/32'
      };
      setUser(mockUser);
      setIsAuthenticated(true);
      setIsLoading(false);
    } else {
      const checkAuth = async () => {
        // get user from local storage
        const user :any = localStorage.getItem('user');
        if (user) {
          setUser(JSON.parse(user));
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }
        try {
          let accessToken = localStorage.getItem('access_token');
          let refreshToken = localStorage.getItem('refresh_token');
          console.log ("Access Token: ", accessToken);
          console.log ("Refresh Token: ", refreshToken);
          const response = await fetch(import.meta.env.VITE_LAMBDA_TWITTER_URL + `?access_token=${accessToken}&refresh_token=${refreshToken}`, {
            method: 'GET',
          });
          console.log ("Response: ", response);
          if (response.ok) {
            const data = await response.json();
            console.log ("Data: ", data);
            const user :any = {
              id: data.user.data.id,
              name: data.user.data.name,
              username: data.user.data.username,
              profile_image_url: data.user.data.profile_image_url
            };
            localStorage.setItem('user', JSON.stringify(user));
            accessToken = data.token.access_token;
            refreshToken = data.token.refresh_token;
            localStorage.setItem('access_token', accessToken!);
            localStorage.setItem('refresh_token', refreshToken!);
            setUser(user);
            setIsAuthenticated(true);
            setIsLoading(false);
            return;
          }
          console.log ("Response not OK");
          setIsAuthenticated(false);
          setIsLoading(false);
        } catch (error: any) {
          console.log ("Error: ", error);
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      };
      checkAuth();
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            process.env.NODE_ENV === 'development' || isAuthenticated ? (
              <ComplaintForm />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/" replace />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/auth/callback" element={<XCallback />} />
        <Route path="/file-upload" element={<FileUploadTest />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
