import React from 'react';

export function Header() {
  return (
    <header className="bg-black text-white p-4 fixed top-0 w-full z-10 border-b border-gray-800">
      <div className="flex items-center space-x-2">
        <div className="w-8 h-8 bg-[#1D9BF0] rounded-lg flex items-center justify-center">
          <span className="text-xl font-bold">C1</span>
        </div>
        <h1 className="text-xl font-bold">CivicsOne</h1>
      </div>
    </header>
  );
}