import React, { useState, useCallback } from 'react'
import { Complaint, categories, complaintTypes } from '../../models/complaint'
import { PlacesAutocomplete } from '../maps/PlacesAutocomplete'
import { Map } from '../maps/Map'
import { format, endOfDay } from 'date-fns'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'
import { Combobox } from '@headlessui/react'
import { Marker } from '@react-google-maps/api'
import { useLoadScript } from '@react-google-maps/api'
import { SubcategorySelector } from '../SubCategorySelector'
import { useComplaintStore } from '../../store/complaintStore'

const defaultCenter = {
  lat: 38.8977,
  lng: -77.0365
}

export function Step2 () {
  const { complaint, updateField } = useComplaintStore()
  const [selectedLocation, setSelectedLocation] = useState(defaultCenter)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [showLocation, setShowLocation] = useState(false)
  const selectedCategory = categories.find(cat => cat.id === complaint.category)

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    },
    debounce: 300,
    initOnMount: true
  })

  const handleLocationSelect = useCallback(
    (location: { lat: number; lng: number }, address: string) => {
      setSelectedLocation(location)
      updateField('location', address)
      map?.panTo(location)
    },
    [map, updateField]
  )

  const handleMapClick = useCallback(
    async (e: google.maps.MapMouseEvent) => {
      if (e.latLng) {
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        setSelectedLocation({ lat, lng })

        try {
          const geocoder = new google.maps.Geocoder()
          const result = await geocoder.geocode({ location: { lat, lng } })
          if (result.results[0]) {
            const address = result.results[0].formatted_address
            updateField('location', address)
          }
        } catch (error) {
          console.error('Error:', error)
        }
      }
    },
    [updateField]
  )

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value)
    updateField('date', date)
  }

  // Get today's date at the end of the day to allow selecting today
  const maxDate = format(endOfDay(new Date()), 'yyyy-MM-dd')

  const handleSelect = async (address: string) => {
    setValue(address, false)
    clearSuggestions()

    try {
      const results = await getGeocode({ address })
      const { lat, lng } = await getLatLng(results[0])

      // Update the selected location and map position
      const newLocation = { lat, lng }
      setSelectedLocation(newLocation)
      map?.panTo(newLocation)

      updateField('location', address)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  })

  if (!isLoaded) {
    return <div>Loading map...</div>
  }

  return (
    <div className='space-y-6 pt-16'>
      <div className='space-y-6'>
        <SubcategorySelector
          question={`Choose a subcategory for ${selectedCategory!.name}`}
          subcategories={selectedCategory!.subcategories!}
          selectedSubcategoryId={complaint.subcategory || ''}
          onSubcategorySelect={subcategoryId =>
            updateField('subcategory', subcategoryId)
          }
        />
      </div>
      <div className='space-y-2'>
        <label className='text-lg font-semibold text-gray-200'>
          Choose the type of government misconduct
        </label>
        <div className='flex gap-4'>
          {complaintTypes.map((type, index) => (
            <button
              key={index}
              onClick={() => updateField('type', type.name)}
              className={`flex-1 p-3 rounded-lg transition-all duration-300
                ${
                  complaint!.type === type.name
                    ? 'bg-[#1D9BF0] text-white'
                    : 'bg-gray-900 text-gray-300 hover:bg-gray-800'
                }`}
            >
              {type.name}
            </button>
          ))}
      </div>
      </div>
      <div className='space-y-2'>
        <label className='text-lg font-semibold text-gray-200'>
          Describe your complaint
        </label>
        <textarea
          className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent
                     min-h-[120px]'
          placeholder='Please provide details...'
          value={complaint.description || ''}
          onChange={e => updateField('description', e.target.value)}
        />
      </div>

      <div className='space-y-2'>
        <div className="flex items-center justify-between">
          <label className='text-lg font-semibold text-gray-200'>
            Where did this occur?
          </label>
          <button
            onClick={() => {
              setShowLocation(!showLocation)
              if (!showLocation) {
                updateField('location', '')
                setSelectedLocation(defaultCenter)
              }
            }}
            className={`px-4 py-2 rounded-lg transition-all duration-300 ${
              showLocation
                ? 'bg-[#1D9BF0] text-white'
                : 'bg-gray-900 text-gray-300 hover:bg-gray-800'
            }`}
          >
            {showLocation ? 'Remove Location' : 'Add Location'}
          </button>
        </div>
        
        {showLocation && (
          <>
            <Combobox onChange={handleSelect}>
              <div className='relative mt-1 z-[9999]'>
                <Combobox.Input
                  className='w-full rounded-lg border-gray-300 bg-gray-700 text-white px-4 py-2'
                  placeholder='Please be as specific with the location as possible...'
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
                <Combobox.Options
                  className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base 
                            shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                >
                  {status === 'OK' &&
                    data.map(({ place_id, description }) => (
                      <Combobox.Option
                        key={place_id}
                        value={description}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-gray-600 text-white' : 'text-gray-300'
                          }`
                        }
                      >
                        {description}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </div>
            </Combobox>
            <Map
              center={selectedLocation}
              onClick={handleMapClick}
              onLoad={onMapLoad}
            >
              <Marker
                position={selectedLocation}
                animation={google.maps.Animation.DROP}
              />
            </Map>
          </>
        )}
      </div>

      <div className='space-y-2'>
        <label className='text-lg font-semibold text-gray-200'>
          When did this occur?
        </label>
        <div className='relative'>
          <input
            type='date'
            className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent appearance-none'
            value={
              complaint.date
                ? format(new Date(complaint.date), 'yyyy-MM-dd')
                : ''
            }
            onChange={handleDateChange}
            max={maxDate}
          />
        </div>
      </div>
    </div>
  )
}
