interface SubcategorySelectorProps {
  question: string;
  subcategories: { id: string; name: string; description?: string }[];
  selectedSubcategoryId: string;
  onSubcategorySelect: (subcategoryId: string) => void;
}

export function SubcategorySelector({
  question,
  subcategories,
  selectedSubcategoryId,
  onSubcategorySelect
}: SubcategorySelectorProps) {
  return (
    <div className="space-y-6">
      <label className="text-lg font-semibold text-gray-200">{question}</label>
      <div className="space-y-3">
        {subcategories.map((subcategory) => (
          <button
            key={subcategory.id}
            onClick={() => onSubcategorySelect(subcategory.id)}
            className={`w-full p-3 rounded-lg transition-all duration-300
              ${selectedSubcategoryId === subcategory.id
                ? 'bg-[#1D9BF0] text-white scale-[1.02]'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700 hover:scale-[1.01]'}`}
          >
            <div className="flex-1 text-left">
              <h4 className="font-medium">{subcategory.name}</h4>
              {subcategory.description && (
                <p className={`text-sm ${
                  selectedSubcategoryId === subcategory.id
                    ? 'text-white'
                    : 'text-gray-400'
                }`}>
                  {subcategory.description}
                </p>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
