import React, { useState } from "react";

export const FileUploadTest = () => {
    const [file, setFile] = useState<any>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

    const handleFileChange = (event: any) => {
      setFile(event.target.files[0]);
    };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file");
      return;
    }

    setUploading(true);
    setProgress(0);
    setError(null);

    try {
      // Step 1: Get pre-signed URL from Lambda
      const response = await fetch("https://he9nwo17ck.execute-api.us-east-1.amazonaws.com/", {
        method: "POST",
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to get pre-signed URL");
      }

      const { uploadURL, key } = await response.json();

      // Step 2: Upload file to S3
      const uploadResponse = await fetch(uploadURL, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file to S3");
      }

      // Step 3: Display uploaded file URL
      const fileUrl = `https://civicsonetest.s3.us-east-1.amazonaws.com/${key}`;
      setUploadedUrl(fileUrl);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h1>Upload Image to S3</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </button>
      {progress > 0 && <p>Progress: {progress}%</p>}
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {uploadedUrl && (
        <p>
          Uploaded URL: <a href={uploadedUrl}>{uploadedUrl}</a>
        </p>
      )}
    </div>
  );
};
