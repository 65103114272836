import React from 'react';

interface TweetDialogProps {
  isOpen: boolean;
  tweet: string;
  onClose: () => void;
}

export function TweetDialog({ isOpen, tweet, onClose }: TweetDialogProps) {
  if (!isOpen) return null;

  const handleTweetClick = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}`;
    window.open(tweetUrl, '_blank');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-xl p-6 max-w-md w-full space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Complaint Submitted!</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <p className="text-gray-300">
          Your complaint has been submitted successfully. Share your voice on X to raise awareness:
        </p>

        <div className="bg-gray-900 p-4 rounded-lg">
          <p className="text-gray-200">{tweet}</p>
        </div>

        <div className="flex space-x-4">
          <button
            onClick={handleTweetClick}
            className="flex-1 bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded-lg 
                     transition-colors flex items-center justify-center space-x-2"
          >
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
            <span>Share on X</span>
          </button>
          <button
            onClick={onClose}
            className="flex-1 bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg 
                     transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}