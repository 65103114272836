import React, { useState, useEffect } from 'react'
import { Complaint } from '../../models/complaint'
import { useComplaintStore } from '../../store/complaintStore'
import useFileHandler from '../../hooks/fileHandler'
import Spinner from '../Spinner'
import { useUserStore } from '../../store/userStore';

export function Step3 () {
  const { user } = useUserStore();
  const { complaint, updateField } = useComplaintStore()
  const [photoEvidence, setPhotoEvidence] = useState<string | null>(null)
  const { handleUpload, uploading, handleFileDelete } = useFileHandler()
  const [showXLinks, setShowXLinks] = useState(false);
  const [xLinksCount, setXLinksCount] = useState(0);

  useEffect(() => {
    updateField('contactName', user?.name ?? '')
    //updateField('email', user.email ?? '')    
  }, []);

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const fileURL :any = await handleUpload(file)
      setPhotoEvidence(fileURL as string)
      updateField('photoEvidence', fileURL as string)
    }
  }

  const handleFileDeleteWrapper = async (s3Url: string) => {
    await handleFileDelete(s3Url)
    setPhotoEvidence(null)
    updateField('photoEvidence', '')
  }

  const addXLink = () => {
    const currentLinks: string[] = Array.isArray(complaint.xLinks) ? complaint.xLinks : [];
    updateField('xLinks', [...currentLinks, '']);
  };

  const updateXLink = (index: number, value: string) => {
    const currentLinks: string[] = Array.isArray(complaint.xLinks) ? complaint.xLinks : [];
    const newLinks = [...currentLinks];
    newLinks[index] = value;
    updateField('xLinks', newLinks);
  };

  return (
    <div className='space-y-6 pt-16'>
      <div className='space-y-2'>
        <label className='text-lg font-semibold text-gray-200'>
          Your contact information
        </label>
        <input
          type='text'
          className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent'
          placeholder='Full Name'
          value={complaint.contactName || ''}
          onChange={e => updateField('contactName', e.target.value)}
        />
      </div>

      <div className='space-y-2'>
        <input
          type='email'
          className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent'
          placeholder='Email'
          value={complaint.email || ''}
          onChange={e => updateField('email', e.target.value)}
        />
      </div>

      <div className='space-y-2'>
        <input
          type='tel'
          className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent'
          placeholder='Phone Number'
          value={complaint.phone || ''}
          onChange={e => updateField('phone', e.target.value)}
        />
      </div>

      <div className='space-y-4'>
        <label className='text-lg font-semibold text-gray-200'>
          Photo or video evidence (Optional)
        </label>
        {uploading && <Spinner />}
        {!photoEvidence && !uploading ? (
          <div className='space-y-4'>
            <div className='relative'>
              <input
                type='file'
                accept='image/*'
                capture='environment'
                onChange={handlePhotoUpload}
                className='hidden'
                id='camera-input'
              />
              <label
                htmlFor='camera-input'
                className='w-full p-4 border-2 border-dashed border-gray-800 rounded-lg 
                         hover:border-[#1D9BF0] transition-colors flex items-center justify-center space-x-2
                         text-gray-400 hover:text-[#1D9BF0] cursor-pointer'
              >
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M15 13a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
                <span>Take or Upload Photo</span>
              </label>
            </div>
          </div>
        ) : (
          <div className='relative'>
            {photoEvidence ? (
              <>
                <img
                  src={photoEvidence}
                  alt='Evidence'
                  className='w-full rounded-lg border border-gray-800'
                />
                <button
                  onClick={() => {
                    handleFileDeleteWrapper(photoEvidence)
                  }}
                  className='absolute top-2 right-2 p-2 bg-red-600 text-white rounded-full 
                       hover:bg-red-500 transition-colors'
                >
                  <svg
                    className='w-5 h-5'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </>
            ) : null}
          </div>
        )}
      </div>

      <div className='space-y-4'>
        <button
          type="button"
          onClick={addXLink}
          className="flex items-center space-x-2 text-[#1D9BF0] hover:text-[#1D9BF0]/80 transition-colors"
        >
          <svg 
            className="w-5 h-5" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <span>Add supporting links</span>
        </button>

        {Array.isArray(complaint.xLinks) && complaint.xLinks.map((link: string, index: number) => (
          <input
            key={index}
            type="url"
            className="w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                      placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent"
            placeholder="Provide x.com or other link here."
            value={link}
            onChange={e => updateXLink(index, e.target.value)}
          />
        ))}
      </div>

      <div className='space-y-2'>
        <label className='text-lg font-semibold text-gray-200'>
          Additional comments
        </label>
        <textarea
          className='w-full p-3 border rounded-lg bg-gray-900 border-gray-800 text-gray-200 
                     placeholder-gray-500 focus:ring-2 focus:ring-[#1D9BF0] focus:border-transparent
                     min-h-[120px]'
          placeholder="Any additional information you'd like to share..."
          value={complaint.evidenceDescription || ''}
          onChange={e => updateField('evidenceDescription', e.target.value)}
        />
      </div>
    </div>
  )
}
