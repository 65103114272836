import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import UserAvatar from './User/UserAvatar'
import { useUserStore } from '../store/userStore';

export default function NavBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const { user } = useUserStore();
  const userData :any = user!;

  // Also log when userData changes
  useEffect(() => {
    console.log('9. Current userData state:', userData);
  }, [userData])

  // Handle clicks outside the dropdown to close it
  const handleDocumentClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      buttonRef.current &&
      !dropdownRef.current.contains(e.target as Node) &&
      !buttonRef.current.contains(e.target as Node)
    ) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleDocumentClick)
    } else {
      document.removeEventListener('click', handleDocumentClick)
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isDropdownOpen])

  // Handle mouse enter on the container
  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
      closeTimeoutRef.current = null
    }
    setIsDropdownOpen(true)
  }

  // Handle mouse leave with a delay
  const handleMouseLeave = () => {
    // Set a timeout to close the dropdown after a short delay
    closeTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false)
      closeTimeoutRef.current = null
    }, 200) // 200ms delay
  }

  return (
    <nav className='fixed top-0 left-0 right-0 bg-black border-b border-gray-800 z-50'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex justify-between items-center h-16'>
          {/* Logo Section */}
          <div className='flex items-center space-x-2'>
            <div className='w-8 h-8 bg-[#1D9BF0] rounded-lg flex items-center justify-center'>
              <span className='text-xl font-bold'>C1</span>
            </div>
            <h1 className='text-xl font-bold text-white'>CivicsOne</h1>
          </div>

          {/* Dropdown Section */}
          <div
            className='relative'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              ref={buttonRef}
              onClick={() => setIsDropdownOpen(prev => !prev)}
              className='px-4 py-2 flex items-center text-sm font-medium text-gray-300 hover:text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
              aria-haspopup='true'
              aria-expanded={isDropdownOpen}
            >
              <UserAvatar url={userData.profile_image_url || 'https://via.placeholder.com/32'} />
            </button>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className='absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50'
              >
                <div className='flex items-center px-4 py-2'>
                  <UserAvatar url={userData.profile_image_url || 'https://via.placeholder.com/32'} />
                  <div>
                    <p className='text-sm font-medium text-black'>{userData.name}</p>
                    <p className='text-xs text-gray-600'>@{userData.username}</p>
                  </div>
                </div>
                <hr className='border-gray-200' />
                <button
                  onClick={() => {
                    // replace with x.com/username in new tab
                    window.open('https://x.com/' + userData.username)
                  }}
                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                >
                  Go to X
                </button>
                <button
                  onClick={() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    navigate('/login')
                  }}
                  className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}
