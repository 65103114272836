import React from 'react';

interface ProgressBarProps {
  currentStep: number;
}

const steps = [
  { number: 1, title: 'Complaint Details' },
  { number: 2, title: 'Location & Date' },
  { number: 3, title: 'Contact Info' }
];

export function ProgressBar({ currentStep }: ProgressBarProps) {
  return (
    <div className="fixed top-[60px] w-full z-10 bg-black border-b border-gray-800 p-4">
      <div className="flex justify-between relative">
        {/* Progress Line */}
        <div className="absolute top-5 left-0 w-full h-1 bg-gray-900">
          <div 
            className="h-full bg-[#1D9BF0] transition-all duration-500 ease-in-out"
            style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
          />
        </div>

        {/* Steps */}
        {steps.map(({ number, title }) => (
          <div key={number} className="relative flex flex-col items-center">
            <div 
              className={`w-10 h-10 rounded-full flex items-center justify-center z-10 transition-all duration-300
                ${currentStep >= number 
                  ? 'bg-[#1D9BF0] text-white' 
                  : 'bg-gray-900 text-gray-500'}`}
            >
              {currentStep > number ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                number
              )}
            </div>
            <span className={`mt-2 text-sm ${currentStep >= number ? 'text-gray-200' : 'text-gray-600'}`}>
              {title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}