import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleXCallback } from '../utils/xAuth';
import { useUserStore } from '../store/userStore';

export function XCallback() {
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      handleXCallback(code)
        .then((data) => {
          console.log ("Callback data", data);
          setUser(data.user);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('access_token', data.token.access_token);
          localStorage.setItem('refresh_token', data.token.refresh_token);
          navigate('/');
        })
        .catch((error) => {
          console.error('X login error:', error);
        //  window.location.replace('/login');
        });
    } else {
      console.error('No code found in URL');
     // window.location.replace('/login');
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <div className="text-white">Authenticating...</div>
    </div>
  );
}
